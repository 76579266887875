import React, { useContext, useEffect, useRef, useState } from 'react'

import Menu from '@mui/icons-material/Menu'
import { Typography } from '@mui/material'
import TouchRipple from '@mui/material/ButtonBase/TouchRipple'
import makeStyles from '@mui/styles/makeStyles'

import { RestaurantContext, UserContext } from '../../App'
import MobileDialog from '../common/MobileDialog'
import Show from '../common/Show'
import { useEntityRelationQuery } from '../common/useEntityQuery'

const defaultTransition = 'opacity 0.2s ease-in, box-shadow 0.2s ease-in, transform 0.2s ease-in'

// CSS
const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: '#FFF',
    transition: defaultTransition,
    cursor: 'pointer',
  },
  title: {
    width: '100%',
    height: 45,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  name: {
    flexGrow: 1,
    // lineHeight: 'unset',
    textAlign: 'left',
    userSelect: 'none',
  },
  price: {
    // lineHeight: 'unset',
    paddingRight: theme.spacing(2),
  },
  dragIcon: {
    cursor: 'grab',
    touchAction: 'none',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  ripple: {
    left: 'unset',
    top: 'unset',
    bottom: 'unset',
    right: 'unset',
    maxWidth: 'calc(100% - 45px)',
    width: 540,
    height: 45,
  },
}))

export default function MenuItemEditDialog(props) {
  console.log('dialog')
  const restaurant = useContext(RestaurantContext)
  const user = useContext(UserContext)

  const [show, setShow] = useState(true)

  const FIELDS = [
    { name: 'id', gql: 'String!', id: true },
    { name: 'name', gql: 'String!', subSelection: { [user.gqlFetchName()]: true } },
    { name: 'description', gql: 'String!', subSelection: { [user.gqlFetchName()]: true } },
    { name: 'menuCategoryId', gql: 'Long!' },
    { name: 'menuCategory', gql: 'MenuCategory!', subSelection: { name: { [user.gqlFetchName()]: true } } },
    { name: 'displayIndex', gql: 'Long!' },
    { name: 'code', gql: 'String!' },
    { name: 'discount', gql: 'Float!' },
    { name: 'price', gql: 'Float!' },
    { name: 'printable', gql: 'Long!' },
    { name: 'offer', gql: 'Long!' },
    { name: 'webOrder', gql: 'Boolean!' },
    { name: 'active', gql: 'Boolean!' },
  ]

  const query = useEntityRelationQuery('MenuItem', FIELDS, 'id', props.menuItemId, {
    single: true,
  })

  console.log(query.item)

  const onClose = () => {
    setShow(false)
  }

  useEffect(() => {
    if (show) return
    const timer = setTimeout(() => {
      props.onClose()
    }, 300)

    return () => {
      clearTimeout(timer)
    }
  }, [show])

  return (
    <MobileDialog open={props.show && show} onClose={onClose} title={props.name}>
      <span>test</span>
    </MobileDialog>
  )
}
